












































































import { Component, Vue } from 'vue-property-decorator'
import { UserModel } from './models/UserModel'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')

import Content from '@/layouts/components/Content.vue'
import BaseTitle from '@/components/base/BaseTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

const ProfileEditForm = () => import('./components/ProfileEditForm.vue')

@Component({
  components: { Content, BaseTitle, BaseButton, BaseIcon, ProfileEditForm },
  metaInfo: {
    title: 'Профиль',
  },
})
export default class ProfilePage extends Vue {
  visibleEditForm = false

  @UserStore.Getter
  public currentUser!: UserModel

  @UserStore.Action
  private getUser!: () => Promise<UserModel>

  public created(): void {
    this.getUser()
  }
}
